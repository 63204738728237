import React from "react"
//import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"
import PageHeader from "../components/page-header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SubHeader from "../components/sub-header"

const About = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const post = data.markdownRemark

  return (
    <Layout title={siteTitle}>
      <SEO title="About" />
      <PageHeader headerText="About" />
      <section className="mw7 center ph3 ph4-m ph5-l avenir fw2 db f5 f4-ns lh-copy bl br b--near-white">
      <p><b>QUASAR</b> is a research group based in Ottawa, Canada.</p>

      <p>QUASAR stands for Quantum Security via Algebras and Representation Theory. The name reflects the focus of our multidisciplinary group of mathematicians, computer scientists, and physicists. Our research centers on three major themes:</p>

      <SubHeader headerText="Quantum entanglement"/>

      <p>With quantum technologies, two distant parties can share a resource—called quantum entanglement—which allows them to jointly behave in a way that is correlated beyond what would be possible using conventional information. Research directions in this domain range from non-local games to questions in noncommutative harmonic analysis (including representation theory) and operator algebras.</p>

      <SubHeader headerText="Post-quantum cryptography"/>
      
      <p>In the face of steady progress towards building quantum computers, there is a pressing need to re-tool our security infrastructure, which currently relies on algorithms that are vulnerable to quantum attacks. Discovering and evaluating quantum-resistant cryptographic algorithms, which run on classical computers, incorporates research in such varied fields as group theory and algebraic geometry, using tools ranging from error-correcting codes to lattices.</p>

      <SubHeader headerText="Quantum protocols"/>
      
      <p>Looking at computations and communications through the unique perspective of quantum information enables us to achieve results that are unimaginable in the classical world. Research into quantum protocols reveals amazing applications, like quantum copy-protection and secure quantum cryptography. The rigorous analysis of these protocols increasingly takes advantage of powerful results from operator algebras and random matrix theory.</p>
      </section>
        {/*<ReactMarkdown
          className="mw7 center ph3 ph4-m ph5-l avenir fw2 db f5 f4-ns lh-copy bl br b--near-white"
          children={post.internal.content}
        />*/}
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      html
      internal {
        content
      }
    }
  }
`
