import React from "react"

const PageHeader = props => {
  return (
    <section className="mw-80 center">
      <header className="avenir tc-l ph3 ph4-ns pt4 pt5-ns mb5 b--black-10">
        <h1 style={{color:"darkred"}} className="f3 f2-m f-subheadline-l measure lh-title fw1 center mt0">
          {props.headerText}
        </h1>
      </header>
    </section>
  )
}

export default PageHeader