import React from "react"

const SubHeader = props => {
  return (
    <section className="mw6 center">
      <header className="avenir tc-l ph3 ph4-ns pt4 pt5-ns mb5 bb b--near-white">
        <h1 style={{color:"darkred"}} className="f-subheadline-1 measure lh-title fw1 center mt0">
          {props.headerText}
        </h1>
      </header>
    </section>
  )
}

export default SubHeader